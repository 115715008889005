































































































































import { Vue, Component, Watch, Ref } from 'vue-property-decorator'
import moment from 'moment'
import pageTitle from '@/components/common/pageTitle.vue'
import pagination from '@/components/common/pagination/index.vue'
import { judgeValue } from '@/utils'
import validator from '@/components/common/validator'
import InputSearch from '@/components/common/InputSearch.vue'
import { ElForm } from 'element-ui/types/form'
import { variables } from '@/libs/theme'
import { actionTypes } from '@/store'

class InitialRuldForm {
  id?: null | string = null
  name: null | string = null
  type: null | string = null
  description: null | string = null
}

@Component({ components: { pageTitle, pagination, InputSearch } })
export default class Tags extends Vue {
  @Ref('tableContiner') readonly tableContiner: HTMLDivElement
  @Ref('formNode') readonly formNode: ElForm
  @Ref('paginationNode') readonly paginationNode: pagination
  private shadow9Color = variables.shadow9Color
  searchName = ''
  tableHeight = 0
  contrasList = []
  loading = false
  sels: AnyObj[] = []
  title = ''
  typeSelect = [
    {
      value: 'vip',
      label: 'VIP'
    },
    {
      value: 'black',
      label: '黑名单'
    },
    {
      value: 'white',
      label: '白名单'
    }
  ]
  tagMap = {
    vip: 'VIP',
    black: '黑名单',
    white: '白名单'
  }
  showFlag = false
  tableLoading = false
  ruleForm = new InitialRuldForm()
  rules = {
    name: [
      {
        required: true,
        validator: validator.checkName({
          num: 256,
          error1: '请输入标签名称',
          error2: '标签名称应在256字符内，请重新输入'
        }),
        trigger: 'blur'
      }
    ],
    description: [{ min: 0, max: 200, message: '备注应在200字符内，请重新输入', trigger: 'blur' }]
  }
  noNet = false
  noData = require('@/assets/images/dashboard/noData.png')
  pageshow = false
  isDisabled = false
  filterFormShow = false
  types: string[] = []
  showTrips = false
  refreshTable = false // 切换路由后还原表头筛选
  pageSize = 20

  @Watch('contrasList')
  watchContrasList() {
    const wrapper = document.getElementsByClassName('el-table__body-wrapper')[0]
    if (wrapper) wrapper.scrollTop = 0
    this.tableLoading = false
  }

  created() {
    this.getContrast()
  }

  activated() {
    this.$nextTick(() => {
      this.tableHeight = this.tableContiner.clientHeight
    })
  }
  deactivated() {
    this.showFlag = false
  }

  tripsLeave() {
    this.showTrips = false
  }

  tripsHover() {
    this.showTrips = true
  }

  pageChange(data: AnyObj) {
    this.pageSize = data.pageSize
    const offset = (data.pageNo - 1) * data.pageSize
    this.getContrast(this.pageSize, offset, this.searchName)
  }

  clickAdd() {
    this.showFlag = !this.showFlag
    this.isDisabled = false
    this.title = '新建标签'
    this.ruleForm = new InitialRuldForm()
    this.$nextTick(() => {
      this.formNode.resetFields()
      this.ruleForm.type = 'white'
    })
  }

  clickEdit({ id, name, type, description }: AnyObj) {
    this.showFlag = !this.showFlag
    this.isDisabled = true
    this.$nextTick(() => {
      this.formNode.resetFields()
      this.title = '编辑标签'
      this.ruleForm.id = id
      this.ruleForm.name = name
      this.ruleForm.type = type
      this.ruleForm.description = description
    })
  }

  cancelDialog() {
    this.showFlag = false
  }

  async getContrast(pageSize = 20, offset = 0, name = '') {
    this.tableLoading = true
    try {
      const types = this.types.length > 0 ? this.types : null
      const resp = await this.$api.base.getContrastList({
        limit: pageSize,
        offset,
        name: name ? name : null,
        types
      })
      if (resp.data && resp.data.code === 0) {
        this.contrasList = resp.data.data
        this.paginationNode.init({ total: resp.data.count })
      }

      this.tableLoading = false
    } catch (error) {
      this.tableLoading = false
      this.noNet = true
    }
  }

  async refresh() {
    this.paginationNode.pageNo = 0
    await this.getContrast(this.pageSize, 0, this.searchName)
  }

  async clickDelete({ id }: AnyObj, index: number, rows: AnyObj[]) {
    this.$confirm('标签一经删除无法恢复，是否确定删除?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      cancelButtonClass: 'message-cancel-btn'
    }).then(async () => {
      const resp = await this.$api.base.getContrastDelete(id)
      rows.splice(index, 1)
      if (resp.status === 204) {
        this.message('删除成功!', 'success')
      }
      this.$store.dispatch(actionTypes.FETCH_TAGS, true)
      this.refresh()
    })
  }

  async clickIntoPersonDetail(row: AnyObj) {
    this.$router.push({
      name: '标签详情',
      query: {
        tagId: row.id,
        tagName: row.name,
        tagType: row.type
      }
    })
  }

  watchInput(val: string) {
    if (!val) {
      this.searchName = ''
      this.refresh()
    }
  }

  async searchTagList(val = '') {
    this.searchName = val
    this.pageshow = false
    const { pageSize } = this.paginationNode.getPageInfo()
    if (this.searchName === null || this.searchName.match(/^[ ]*$/)) {
      return false
    } else {
      await this.getContrast(pageSize, 0, this.searchName.replace(/\s+/g, ''))
    }
    this.$nextTick(() => {
      this.pageshow = true
    })
  }

  message(msg: string, type: MessageType) {
    this.$message({
      message: msg,
      type: type
    })
  }

  selsChange(sels: AnyObj[]) {
    this.sels = sels
  }

  dateFormat(row: AnyObj[], column: AnyObj) {
    const date = row[column.property]
    if (date == undefined) {
      return ''
    }
    return moment(date).format('YYYY-MM-DD HH:mm:ss')
  }

  submit() {
    this.formNode.validate(async (valid: boolean) => {
      if (valid) {
        this.loading = true
        let res
        try {
          judgeValue(this.ruleForm)
          if (this.title === '新建标签') {
            delete this.ruleForm.id
            res = await this.$api.base.getContrastCreate(this.ruleForm)
            if (res.data && res.data.code === 0) this.message('新增成功', 'success')
            this.$store.dispatch(actionTypes.FETCH_TAGS, true)
            this.showFlag = false
          } else {
            res = await this.$api.base.getEditContrast(this.ruleForm.id || '', this.ruleForm)
            if (res.data && res.data.code === 0) this.message('编辑成功', 'success')
            this.$store.dispatch(actionTypes.FETCH_TAGS, true)
            this.showFlag = false
          }

          if (res.data && res.data.code != 0) {
            this.loading = false
            this.showFlag = true
          }
          this.refresh()
        } catch (error) {
          if (error) {
            this.loading = false
            return false
          }
        }
      }
    })
  }

  selectTag(types = []) {
    this.types = types

    const dom = document.getElementsByClassName(
      'icon-shaixuan'
    ) as HTMLCollectionOf<HTMLSpanElement>

    if (this.types.length > 0) {
      dom[0].style.color = variables.colorPrimary
    } else {
      dom[0].style.color = variables.greyColor
    }
    if (this.searchName === null || this.searchName.match(/^[ ]*$/)) {
      this.getContrast()
    } else {
      this.searchTagList(this.searchName)
    }
  }
}
