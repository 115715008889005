const tel = ({ type = true }) => {
  return (rule, value, callback) => {
    if (value === '' || value === null || value === undefined) {
      if (type) {
        callback()
      } else {
        callback(new Error('请输入手机号'))
      }
    }
    if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(value)) {
      callback(new Error('请输入正确的手机号'))
    }
    callback()
  }
}
const Email = ({ type = true }) => {
  return (rule, value, callback) => {
    if (value === '' || value === null || value === undefined) {
      if (type) {
        callback()
      } else {
        callback(new Error('请输入邮箱号'))
      }
    }
    if (!/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)) {
      callback(new Error('请输入有效邮箱'))
    }
    callback()
  }
}
const checkName = ({ num, error1, error2 }) => {
  return (rule, value, callback) => {
    if (value) {
      const str = value.replace(/^\s+|\s+$/g, '')
      if (str.length > num) {
        callback(new Error(error2))
      } else if (str.length === 0) {
        callback(new Error(error1))
      } else {
        callback()
      }
    } else {
      callback(new Error(error1))
    }
  }
}
const longitude = ({ type = true }) => {
  return (rule, value, callback) => {
    if (value === '' || value === null || value === undefined) {
      if (type) {
        callback()
      } else {
        callback(new Error('请输入经度'))
      }
    }
    if (!/^(-?\d+)(\.\d+)?$/.test(value) || value < -180.0 || value > 180.0) {
      callback(new Error('请输入正确的经度'))
    }
    callback()
  }
}

const latitude = ({ type = true }) => {
  return (rule, value, callback) => {
    if (value === '' || value === null || value === undefined) {
      if (type) {
        callback()
      } else {
        callback(new Error('请输入纬度'))
      }
    }
    if (!/^(-?\d+)(\.\d+)?$/.test(value) || value < -90.0 || value > 90.0) {
      callback(new Error('请输入正确的纬度'))
    }
    callback()
  }
}

const isNumber = () => {
  return (rule, value, callback) => {
    if (value && !/^\d+(\.\d+)?$/.test(value)) {
      callback(new Error('请输入数字'))
    }
    callback()
  }
}

export default {
  tel,
  Email,
  checkName,
  longitude,
  latitude,
  isNumber
}
